
import { Component, Vue } from 'vue-property-decorator';
import { ServiceManager, ServiceType, Logger, LogLevel } from 'client-website-ts-library/services';
import { Firebase, FirebaseMessageDelegate, FirebaseMessageData } from 'client-website-ts-library/services/ThirdParty/Firebase';

import Notification from './Notification.vue';

@Component({
  components: {
    Notification,
  },
})
export default class NotificationStack extends Vue implements FirebaseMessageDelegate {
  private notifications: FirebaseMessageData[] = [];

  mounted() {
    const fcm = ServiceManager.Get(ServiceType.Firebase) as Firebase;

    fcm.SetForegroundMessageDelegate(this);
  }

  OnMessage(message: FirebaseMessageData) {
    this.notifications.push(message);

    Logger.Log(LogLevel.Debug, 'Notification Received', message);
  }

  handleRemoved(id: string): void {
    const idx = this.notifications.findIndex((n) => n.id === id);

    if (idx !== -1) this.notifications.splice(idx, 1);
  }
}
