import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  {
    path: '/properties/for-sale',
    name: 'Properties For Sale',
    component: () => import('../views/PropertiesForSale.vue'),
  },
  {
    path: '/properties/sold',
    name: 'Sold Properties',
    component: () => import('../views/PropertiesSold.vue'),
  },
  {
    path: '/forms/sales-appraisal',
    name: 'Sales Appraisal',
    component: () => import('../views/SalesAppraisal.vue'),
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue'),
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: () => import('../views/ContactUs.vue'),
  },
  {
    path: '/blogs/:id',
    name: 'Blog',
    meta: {
      hide: true,
    },
    component: () => import('../views/BlogDetails.vue'),
  },
  {
    path: '/listings/:id',
    name: 'Listing',
    meta: {
      hide: true,
    },
    component: () => import('../views/ListingDetails.vue'),
  },
  {
    path: '/profiles/:id',
    name: 'Profile',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfile.vue'),
  },
  {
    path: '/profiles/:id/properties',
    name: 'Profile Listings',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListings.vue'),
  },
  {
    path: '/profiles/:id/properties/sold',
    name: 'Profile Sold Listings',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListingsSold.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/sitemap',
    name: 'Sitemap',
    component: () => import('../views/Sitemap.vue'),
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    meta: {
      hide: true,
    },
    component: () => import('../views/Unsubscribe.vue'),
  },
  {
    path: '/*',
    name: 'Not Found',
    meta: {
      hide: true,
    },
    component: () => import('../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

export default router;
